//import Vue from "vue";
import * as API from "@/services/API";
import ChatMessageService from "@/services/ChatMessageService";
import NotificationService from "@/services/NotificationService";

export const namespaced = true;

export const state = {
    conversations: [],
    currentConversationId: null,
    messages: [],
    emojis: [],
    currentMessages: [],
    currentBotEnabled: false,
    usersOnline: [],
    typingUsers: [],
    typingTimers: {},
    loading: false,
    error: null,
};

export const mutations = {
    SET_MESSAGES(state, payload) {
        state.conversations = payload;
    },
    SET_EMOJIS(state, payload) {
        state.emojis = payload;
    },
    SET_CURRENT_MESSAGES(state, payload) {
        let index = state.conversations.findIndex((e) => e.id === payload);
        if (index !== -1) {
            state.currentMessages = state.conversations[index].messages;
            state.conversations[index].unreadCount = 0;
        }
    },
    SET_CONVERSATION_IS_BOT_ENABLED(state, value) {
        let index = state.conversations.findIndex(
            (e) => e.id === state.currentConversationId
        );
        if (index !== -1) {
            state.conversations[index].is_bot_enabled = value;
        }
    },
    SET_ALL_CONVERSATION_IS_BOT_ENABLED(state, value) {
        for (let key in state.conversations) {
            state.conversations[key].is_bot_enabled = value;
        }
    },
    SET_CURRENT_BOT_ENABLED(state) {
        let index = state.conversations.findIndex(
            (e) => e.id === state.currentConversationId
        );
        if (index !== -1) {
            state.currentBotEnabled = state.conversations[index].is_bot_enabled;
        }
    },
    SET_CURRENT_CONVERSATION_ID(state, payload) {
        state.currentConversationId = payload;
    },
    ADD_MESSAGE(state, payload) {
        let index = state.conversations.findIndex(
            (e) => e.id === parseInt(payload.conversation_id)
        );
        if (index !== -1) {
            state.conversations[index].messages.push(payload);
            if (state.conversations[index].id == state.currentConversationId) {
                state.currentMessages = state.conversations[index].messages;
            }
            if (payload.sender_type === "visitor") {
                state.conversations[index].unreadCount++;
                state.conversations[index].isOnline = true;
            }
        }
    },
    UPDATE_MESSAGE(state, payload) {
        let convIndex = state.conversations.findIndex(
            (e) => e.id === parseInt(payload.conversation_id)
        );
        let msgIndex = state.conversations[convIndex].messages.findIndex(
            (message) => message.id === payload.id
        );
        if (convIndex !== -1 && msgIndex !== -1) {
            state.conversations[convIndex].messages[msgIndex].text =
                payload.text;
            //state.currentMessages = state.conversations[convIndex].messages;
            if (payload.author_type === "App\\Models\\Visitor") {
                state.conversations[convIndex].isOnline = true;
            }
        }
    },
    ADD_CONVERSATION(state, payload) {
        state.conversations.push(payload);
    },
    USERS_ONLINE(state, payload) {
        state.usersOnline = payload;
    },
    USER_JOINING(state, payload) {
        state.usersOnline.push(payload);
    },
    USER_LEAVING(state, payload) {
        state.usersOnline.splice(state.usersOnline.indexOf(payload), 1);
    },
    SET_VISITORS_ONLINE(state, payload) {
        let conversationsOnline = payload.map((e) => {
            return e.conversation_id;
        });
        for (let i = 0; i < state.conversations.length; i++) {
            state.conversations[i].isOnline = conversationsOnline.includes(
                state.conversations[i].id
            );
        }
    },
    VISITOR_JOINING(state, payload) {
        let index = state.conversations.findIndex(
            (e) => e.id === parseInt(payload)
        );
        if (index !== -1) {
            state.conversations[index]["isOnline"] = true;
        }
    },
    VISITOR_LEAVING(state, payload) {
        let index = state.conversations.findIndex(
            (e) => e.id === parseInt(payload)
        );
        if (index !== -1) {
            state.conversations[index]["isOnline"] = false;
        }
    },
    SET_BOT_SUGGESTION(state, payload) {
        let index = state.conversations.findIndex(
            (e) => e.id === parseInt(payload.conversation_id)
        );
        if (index !== -1) {
            state.conversations[index]["botSuggestion"] =
                payload.suggestion_text;
        }
    },
    SET_LAST_HREF(state, payload) {
        let index = state.conversations.findIndex(
            (e) => e.id === parseInt(payload.conversation_id)
        );
        if (index !== -1) {
            state.conversations[index]["last_href"] = payload.last_href;
        }
    },
    ADD_TYPING_USER(state, payload) {
        let index = state.conversations.findIndex(
            (e) => e.id === parseInt(payload.conversation_id)
        );
        if (index !== -1) {
            state.conversations[index].isTyping = true;
            if (state.typingTimers[payload.user.id]) {
                clearTimeout(state.typingTimers[payload.user.id]);
            }
            state.typingTimers[payload.user.id] = setTimeout(() => {
                state.conversations[index].isTyping = false;
            }, 2000);
        }
        // let index = state.typingUsers.findIndex((e) => e.id === payload.id);
        // if (index == -1) {
        //     state.typingUsers.push(payload);
        //     if (state.typingTimers[payload.user.id]) {
        //         clearTimeout(state.typingTimers[payload.user.id]);
        //     }
        //     state.typingTimers[payload.user.id] = setTimeout(() => {
        //         let userIndex = state.typingUsers.findIndex(
        //             (e) => e.id === payload.user.id
        //         );
        //         state.typingUsers.splice(userIndex, 1);
        //     }, 1500);
        // }
    },
    REMOVE_TYPING_USER(state, payload) {
        let index = state.typingUsers.findIndex((e) => e.id === payload.id);
        if (index !== -1) {
            state.typingUsers.splice(index, 1);
        }
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    UPDATE_FEEDBACK(state, payload) {
        state.conversations = state.conversations.map(function (c) {
            if (c.id == payload.conversation_id) {
                c.messages.map(function (m) {
                    if (m.id == payload.id) {
                        m.feedback = payload.feedback;
                    }
                    return m;
                });
            }
            return c;
        });
    },
    INCREMENT_UNREAD_COUNT(state, payload) {
        const old = (m) => Date.parse(m.created_at) > payload;
        state.conversations.forEach((c, index) => {
            if (c.messages.some(old)) {
                state.conversations[index].unreadCount++;
            }
        });
    },
};

export const actions = {
    switchConversation({ commit }, conversationId) {
        commit("SET_CURRENT_MESSAGES", conversationId);
        commit("SET_CURRENT_CONVERSATION_ID", conversationId);
        commit("SET_CURRENT_BOT_ENABLED");
    },
    getChatMessages({ commit }) {
        commit("SET_LOADING", true);
        return ChatMessageService.getMessages()
            .then((response) => {
                let payload = response.data.data;
                for (let i in payload) {
                    payload[i].unreadCount = 0;
                    //payload[i].botSuggestion = "";
                }
                commit("SET_MESSAGES", payload);
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                console.log(error);
            });
    },
    getEmojis({ commit }) {
        return ChatMessageService.getEmojis()
            .then((response) => {
                commit("SET_EMOJIS", response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    setConversationIsBotEnabled({ commit }, value) {
        return API.apiClient
            .post(
                `/conversations/${state.currentConversationId}/set-bot-enabled`,
                { value: value }
            )
            .then(() => {
                commit("SET_CONVERSATION_IS_BOT_ENABLED", value);
                commit("SET_CURRENT_BOT_ENABLED");
            })
            .catch((error) => {
                commit("SET_CURRENT_BOT_ENABLED");
                alert(error.response.data.message);
            });
    },
    postMessage({ commit }, payload) {
        commit;
        return ChatMessageService.postMessage(payload);
    },
    updateMessage({ commit }, payload) {
        commit;
        return ChatMessageService.updateMessage(payload);
    },
    addChatMessage({ commit }, payload) {
        commit("ADD_MESSAGE", payload);
        if (payload.sender_type == "visitor" && payload.type == "text") {
            NotificationService.sendNotification(
                "Nouveau message (conv. n°" + payload.conversation_id + ")",
                payload.text.slice(0, 50) +
                    (payload.text.length > 50 ? "..." : "")
            );
        }
    },
    updateChatMessage({ commit }, payload) {
        commit("UPDATE_MESSAGE", payload);
    },
    addConversation({ commit }, payload) {
        commit("ADD_CONVERSATION", {
            ...payload,
            ...{ isOnline: true, unreadCount: 0, botSuggestion: "" },
        });
        NotificationService.sendNotification(
            "Nouvelle conversation ",
            "Un visiteur à ouvert une conversation"
        );
    },
    updateUsersOnline({ commit }, payload) {
        commit("USERS_ONLINE", payload);
    },
    userJoining({ commit }, payload) {
        commit("USER_JOINING", payload);
    },
    userLeaving({ commit }, payload) {
        commit("USER_LEAVING", payload);
    },
    setVisitorsOnline({ commit }, payload) {
        commit("SET_VISITORS_ONLINE", payload);
    },
    visitorJoining({ commit }, payload) {
        commit("VISITOR_JOINING", payload);
    },
    visitorLeaving({ commit }, payload) {
        commit("VISITOR_LEAVING", payload);
    },
    addBotSuggestion({ commit }, payload) {
        commit("SET_BOT_SUGGESTION", payload);
    },
    setConversationLastHref({ commit }, payload) {
        commit("SET_LAST_HREF", payload);
    },
    addTypingUser({ commit }, payload) {
        commit("ADD_TYPING_USER", payload);
    },
    removeTypingUser({ commit }, payload) {
        commit("REMOVE_TYPING_USER", payload);
    },
    feedbackUpdated({ commit }, payload) {
        commit("UPDATE_FEEDBACK", payload);
    },
    setUnreadFromDate({ commit }, payload) {
        commit("INCREMENT_UNREAD_COUNT", payload);
    },
};

export const getters = {
    conversations: (state) => {
        // return state.conversations.map((e) => {
        //   return { id: e.id, visitor_id: e.visitor_id, isOnline: e.isOnline, is_bot_enabled: e.is_bot_enabled, unreadCount: e.unreadCount };
        // })
        return state.conversations;
    },
    emojis: (state) => {
        return state.emojis;
    },
    currentMessages: (state) => {
        return state.currentMessages;
    },
    currentMessagesLength: (state) => {
        return state.currentMessages.length;
    },
    currentConversationId: (state) => {
        return state.currentConversationId;
    },
    conversationIsBotEnabled: (state) => {
        let index = state.conversations.findIndex(
            (e) => e.id === state.currentConversationId
        );
        return index !== -1
            ? !!state.conversations[index].is_bot_enabled
            : false;
    },
    visitorInitiatedConversation: (state) => {
        return state.currentMessages.some((m) => m.sender_type === "visitor");
    },
    currentBotEnabled: (state) => {
        return state.currentBotEnabled;
    },
    botSuggestion: (state) => {
        let index = state.conversations.findIndex(
            (e) => e.id === state.currentConversationId
        );
        return index !== -1 ? state.conversations[index].botSuggestion : "";
    },
    lastHref: (state) => {
        let index = state.conversations.findIndex(
            (e) => e.id === state.currentConversationId
        );
        return index !== -1 ? state.conversations[index].last_href : "";
    },
    messages: (state) => {
        return state.messages;
    },
    usersOnline: (state) => {
        return state.usersOnline;
    },
    typingUsers: (state) => {
        return state.typingUsers;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};
