import * as API from "@/services/API";
import { getErrors } from "../../utils/helpers";

export const namespaced = true;

export const state = {
    settings: {
        is_bot_enabled: false,
        is_hello_message: false,
        hello_message: "",
        hello_message_delay: "",
    },
    shortcuts: "",
    responseMessage: "",
    errors: "",
};

export const mutations = {
    SET_SETTINGS(state, payload) {
        state.settings = payload;
    },
    SET_RESPONSE_MESSAGE(state, payload) {
        state.responseMessage = payload;
    },
    SET_ERROR(state, payload) {
        state.errors = payload;
    },
};

export const actions = {
    getSettings({ commit }) {
        return API.apiClient
            .get("/website-settings")
            .then((response) => {
                commit("SET_SETTINGS", response.data.data);
            })
            .catch((error) => {
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    updateSettings({ commit }, value) {
        return API.apiClient
            .put("/website-settings", value)
            .then(() => {
                commit("SET_RESPONSE_MESSAGE", "Settings updated!");
                commit("SET_ERROR", "");
                commit("auth/SET_IS_BOT_ENABLED", value.is_bot_enabled, {
                    root: true,
                });
                commit(
                    "chatMessage/SET_ALL_CONVERSATION_IS_BOT_ENABLED",
                    value.is_bot_enabled,
                    { root: true }
                );
                commit("chatMessage/SET_CURRENT_BOT_ENABLED", null, {
                    root: true,
                });
            })
            .catch((error) => {
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    resetResponseMessage({ commit }) {
        commit("SET_RESPONSE_MESSAGE", "");
        commit("SET_ERROR", "");
    },
};

export const getters = {
    settings: (state) => {
        return state.settings;
    },
    shortcuts: (state) => {
        return state.shortcuts;
    },
    responseMessage: (state) => {
        return state.responseMessage;
    },
    errors: (state) => {
        return state.errors;
    },
};
