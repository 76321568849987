import * as API from "@/services/API";

export default {
    getUser(userId) {
        return API.apiClient.get(`/users/${userId}`);
    },
    getUsers(page) {
        page;
        return API.apiClient.get(`/users`);
    },
    paginateUsers(link) {
        return API.apiClient.get(link);
    },
    updateUser(payload) {
        return API.apiClient.put(`/users/${payload.id}`, payload);
    },
    createUser(payload) {
        return API.apiClient.post(`/users`, payload);
    },
    deleteUser(id) {
        return API.apiClient.delete(`/users/${id}`);
    },
};
