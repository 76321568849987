import { getErrors } from "@/utils/helpers";
import UserService from "@/services/UserService";
import WebsiteService from "@/services/WebsiteService";

export const namespaced = true;

function userNulled() {
    return {
        name: null,
        email: null,
        websites: [],
        password: null,
        password_confirmation: null,
    };
}

export const state = {
    users: [],
    meta: null,
    links: null,
    loading: false,
    errors: null,
    editedUser: userNulled(),
    responseMessage: null,
};

export const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_EDITED_USER(state, payload) {
        payload.websites = payload.websites.map((w) => w.id);
        state.editedUser = payload;
    },
    DELETE_USER(state, payload) {
        let index = state.users.findIndex((e) => e.id === payload);
        if (index !== -1) {
            state.users.splice(index, 1);
        }
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.errors = error;
    },
    SET_RESPONSE_MESSAGE(state, payload) {
        state.responseMessage = payload;
    },
};

export const actions = {
    resetForm({ commit }) {
        commit("SET_EDITED_USER", userNulled());
    },
    getWebsites() {
        return WebsiteService.getWebsites(1);
    },
    getUsers({ commit }, page) {
        commit("SET_LOADING", true);
        return UserService.getUsers(page)
            .then((response) => {
                commit("SET_USERS", response.data.data);
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    getUser({ commit }, id) {
        commit("SET_LOADING", true);
        if (id !== "create") {
            return UserService.getUser(id)
                .then((response) => {
                    commit("SET_EDITED_USER", response.data.data);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    commit("SET_ERROR", getErrors(error));
                    commit("SET_RESPONSE_MESSAGE", "");
                });
        }
    },
    createUser({ commit }, payload) {
        commit("SET_LOADING", true);
        return UserService.createUser(payload)
            .then((response) => {
                commit("SET_EDITED_WEBSITE", response.data.data);
                commit("SET_RESPONSE_MESSAGE", "User created!");
                commit("SET_ERROR", "");
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    updateUser({ commit }, payload) {
        commit("SET_LOADING", true);
        return UserService.updateUser(payload)
            .then((response) => {
                commit("SET_EDITED_USER", response.data.data);
                commit("SET_RESPONSE_MESSAGE", "User updated!");
                commit("SET_ERROR", "");
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    deleteUser({ commit }, payload) {
        return UserService.deleteUser(payload)
            .then((response) => {
                response;
                commit("DELETE_USER", payload);
                commit("SET_RESPONSE_MESSAGE", "Website deleted!");
                commit("SET_ERROR", "");
            })
            .catch((error) => {
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    resetResponseMessage({ commit }) {
        commit("SET_RESPONSE_MESSAGE", "");
        commit("SET_ERROR", "");
    },
};

export const getters = {
    users: (state) => {
        return state.users;
    },
    editedUser: (state) => {
        return state.editedUser;
    },
    meta: (state) => {
        return state.meta;
    },
    links: (state) => {
        return state.links;
    },
    loading: (state) => {
        return state.loading;
    },
    errors: (state) => {
        return state.errors;
    },
    responseMessage: (state) => {
        return state.responseMessage;
    },
};
