import * as API from "@/services/API";

export default {
    getWebsite(id) {
        return API.apiClient.get(`/websites/${id}`);
    },
    getWebsites(page) {
        page;
        return API.apiClient.get(`/websites`);
    },
    paginateWebsites(link) {
        return API.apiClient.get(link);
    },
    updateWebsite(payload) {
        return API.apiClient.put(`/websites/${payload.id}`, payload);
    },
    createWebsite(payload) {
        return API.apiClient.post(`/websites`, payload);
    },
    deleteWebsite(id) {
        return API.apiClient.delete(`/websites/${id}`);
    },
};
