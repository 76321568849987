export default {
    showNotification(title, body) {
        let notification = new Notification(title, {
            body: body,
            icon: "https://vasa.fr/img/favicons/apple-icon-120x120.png",
        });
        notification.onclick = () => {
            window.open(window.location.href);
        };
    },

    sendNotification(title, body) {
        if ("Notification" in window) {
            if (Notification.permission === "granted") {
                this.showNotification(title, body);
            } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(function (permission) {
                    if (permission === "granted") {
                        this.showNotification(title, body);
                    }
                });
            }
        }
    },
};
