import FeedbackService from "@/services/FeedbackService";

export const namespaced = true;

export const state = {
    alternative_answers: [],
    export_alternative_answers: [],
};

export const mutations = {
    SET_ALTERNATIVE_ANSWERS(state, payload) {
        state.alternative_answers = payload;
    },
    SET_MODERATION(state, payload) {
        state.alternative_answers.map((x) => {
            if (x.id == payload.id) {
                x.moderation = payload.moderation;
            }
            return x;
        });
    },
    SET_ALT_TEXT(state, payload) {
        state.alternative_answers.map((x) => {
            if (x.id == payload.id) {
                x.alternative_answer = payload.alternative_answer;
            }
            return x;
        });
    },
    SET_EXPORT_ALTERNATIVE_ANSWERS(state, payload) {
        state.export_alternative_answers = payload;
    },
};

export const actions = {
    getAlternativeAnswers({ commit }) {
        return FeedbackService.getAlternativeAnswers()
            .then((response) => {
                commit("SET_ALTERNATIVE_ANSWERS", response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updateModeration({ commit }, payload) {
        document.getElementById(payload.id).style.opacity = "0";
        document.getElementById(payload.id).style.transition = "opacity 1s";

        setTimeout(() => {
            return FeedbackService.updateModeration(payload)
                .then((response) => {
                    commit("SET_MODERATION", response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }, 800);
    },
    updateAltAnswerText({ commit }, payload) {
        return FeedbackService.updateAltAnswerText(payload)
            .then((response) => {
                commit("SET_ALT_TEXT", response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    exportAlternativeAnswers({ commit }) {
        return FeedbackService.exportAlternativeAnswers()
            .then((response) => {
                commit("SET_EXPORT_ALTERNATIVE_ANSWERS", response.data);
                if (response.data == "empty"){
                    alert("Notez des réponses pour pouvoir exporter.");
                }else{
                    alert("Les réponses ont bien été exportées.");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
};

export const getters = {
    alternativeAnswers: (state) => {
        return state.alternative_answers;
    },
    eAlternativeAnswers: (state) => {
        return state.export_alternative_answers;
    },
};
