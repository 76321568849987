import * as API from "@/services/API";

export default {
    getMessages() {
        return API.apiClient.get("/chat_messages");
    },

    postMessage(payload) {
        return API.apiClient.post("/chat_messages", payload);
    },

    updateMessage(payload) {
        return API.apiClient.put(`/chat_messages/${payload.id}`, payload);
    },

    getEmojis() {
        return API.apiClient.get("/chat_emojis");
    },
};
