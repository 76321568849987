<template>
    <v-app>
        <!-- <vue-progress-bar v-if="authUser"></vue-progress-bar> -->
        <template v-if="!authUser">
            <router-view></router-view>
        </template>
        <template v-if="authUser">
            <v-navigation-drawer
                class="indigo lighten-4"
                mini-variant-width="70"
                width="250"
                light
                :mini-variant.sync="mini"
                v-model="drawer"
                app
            >
                <v-list class="pa-0">
                    <v-list-item tag="div">
                        <v-list-item-action style="margin: 16px">
                            <img
                                :src="authUser.avatar"
                                class="profile_avatar"
                            />
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                authUser.name
                            }}</v-list-item-title>
                            <v-list-item-title
                                style="margin-top: 8px; font-size: 70%"
                                v-for="website in authUser.websites.map(
                                    (w) => w.domain
                                )"
                                :key="`user-website-${website}`"
                            >
                                {{ website }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <!-- <v-spacer></v-spacer> -->
                        <v-list-item-action style="max-width: 0.5em; margin: 0">
                            <v-btn icon small @click.native.stop="mini = !mini">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-list>
                    <v-list-item
                        v-for="item in menuItems"
                        :key="item.title"
                        :class="
                            activeMenuItem.includes(item.title)
                                ? 'indigo lighten-3 text'
                                : ''
                        "
                        @click="handleNavigtiion(item)"
                    >
                        <v-list-item-action class="pr-1 pl-2 mr-1">
                            <v-icon :title="item.title">
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="item.title"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <!-- -->
            </v-navigation-drawer>
            <v-app-bar app color="indigo" dark>
                <v-app-bar-nav-icon
                    @click.native.stop="drawer = !drawer"
                ></v-app-bar-nav-icon>
                <v-app-bar-title>Chatbot App</v-app-bar-title>

                <v-spacer></v-spacer>

                <v-btn text @click="gotoAccount">
                    <span>Account</span>
                </v-btn>

                <v-btn text @click="logout">
                    <span>Logout</span>
                </v-btn>
            </v-app-bar>
            <v-main>
                <div class="container-main">
                    <router-view></router-view>
                </div>
            </v-main>

            <v-footer app>
                <!-- -->
            </v-footer>
        </template>
    </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "App",
    data: () => ({
        items: [
            {
                icon: "mdi-chat-question-outline",
                title: "Chats",
                vertical: "Chats",
                link: "chats",
                forAdmins: false,
            },
            {
                icon: "mdi-account",
                title: "Users",
                vertical: "Users",
                link: "users",
                forAdmins: true,
            },
            {
                icon: "mdi-web",
                title: "Websites",
                vertical: "Websites",
                link: "websites",
                forAdmins: true,
            },
            {
                icon: "mdi-message-question",
                title: "Alternative Answers",
                vertical: "Alternative Answers",
                link: "alternative-answers",
                forAdmins: false,
            },
            {
                icon: "mdi-cog-outline",
                title: "Settings",
                vertical: "Settings",
                link: "website-settings",
                forAdmins: true,
            },
            // {
            //     icon: 'mdi-cog-outline',
            //     title: 'VChat',
            //     vertical: 'VChat',
            //     link: 'vchat',
            //     forAdmins: false,
            // },
        ],
        drawer: window.innerWidth > 960,
        mini: false,
        activeMenuItem: "",
    }),
    computed: {
        ...mapGetters("auth", ["authUser", "isAdmin"]),
        menuItems() {
            return this.items.filter((e) => this.isAdmin || !e.forAdmins);
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("auth/logout");
        },
        gotoAccount() {
            this.activeMenuItem = "";
            this.$router.push({ name: "user" });
        },
        handleNavigtiion(item) {
            this.activeMenuItem = item.title;
            this.$router.push({ name: item.link });
        },
    },
};
</script>

<style>
.profile_avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
}
</style>
