import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";
import auth from "@/middleware/auth";
import admin from "@/middleware/admin";
import guest from "@/middleware/guest";
import middlewarePipeline from "@/router/middlewarePipeline";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        meta: { middleware: [guest] },
        component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
    },
    {
        path: "/chats",
        name: "chats",
        meta: { middleware: [auth] },
        component: () =>
            import(/* webpackChunkName: "chats" */ "../views/Chats"),
    },
    {
        path: "/user",
        name: "user",
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "user" */ "../views/User"),
    },
    {
        path: "/users",
        name: "users",
        meta: { middleware: [auth, admin] },
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/Users"),
    },
    {
        path: "/users/:id",
        name: "user-edit",
        meta: { middleware: [auth, admin] },
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/UserEdit"),
    },
    {
        path: "/websites",
        name: "websites",
        meta: { middleware: [auth, admin] },
        component: () =>
            import(/* webpackChunkName: "websites" */ "../views/Websites"),
    },
    {
        path: "/websites/:id",
        name: "website-edit",
        meta: { middleware: [auth, admin] },
        component: () =>
            import(/* webpackChunkName: "websites" */ "../views/WebsiteEdit"),
    },
    {
        path: "/alternative-answers",
        name: "alternative-answers",
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "Alternative Answers" */ "../views/AlternativeAnswers"),
    },
    {
        path: "/settings",
        name: "website-settings",
        meta: { middleware: [auth] },
        component: () =>
            import(
                /* webpackChunkName: "websites" */ "../views/WebsiteSettings"
            ),
    },
    {
        path: "/vchat",
        name: "vchat",
        meta: { middleware: [auth] },
        component: () =>
            import(/* webpackChunkName: "websites" */ "../views/VuetifyChat"),
    },
    {
        path: "/login",
        name: "login",
        meta: { middleware: [guest] },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login"),
    },
    // {
    //   path: "/register",
    //   name: "register",
    //   meta: { middleware: [guest] },
    //   component: () =>
    //     import(/* webpackChunkName: "register" */ "../views/Register"),
    // },
    // {
    //   path: "/reset-password",
    //   name: "resetPassword",
    //   meta: { middleware: [guest] },
    //   component: () =>
    //     import(/* webpackChunkName: "reset-password" */ "../views/ResetPassword"),
    // },
    // {
    //   path: "/forgot-password",
    //   name: "forgotPassword",
    //   meta: { middleware: [guest] },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "forgot-password" */ "../views/ForgotPassword"
    //     ),
    // },
    {
        path: "/:catchAll(.*)",
        name: "notFound",
        component: () =>
            import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware;
    const context = { to, from, next, store };

    if (!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

export default router;
