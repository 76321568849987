import * as API from "@/services/API";

export default {
    getAlternativeAnswers() {
        return API.apiClient.get("/feedbacks/alternative-answers");
    },
    updateModeration(payload) {
        return API.apiClient.put("/feedbacks/alternative-answers", payload);
    },
    updateAltAnswerText(payload) {
        return API.apiClient.put("/feedbacks/alternative-answers/edit", payload);
    },
    exportAlternativeAnswers() {
        return API.apiClient.get("/feedbacks/alternative-answers/export");
    },
};
