import Vue from "vue";
import Vuex from "vuex";

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as website from "@/store/modules/Website";
import * as websiteSettings from "@/store/modules/WebsiteSettings";
import * as chatMessage from "@/store/modules/ChatMessage";
import * as feedback from "@/store/modules/Feedback";

Vue.use(Vuex);

export default new Vuex.Store({
    // !!!
    strict: false,

    modules: {
        auth,
        user,
        website,
        websiteSettings,
        chatMessage,
        feedback,
    },
});
