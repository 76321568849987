import { getErrors } from "@/utils/helpers";
import WebsiteService from "@/services/WebsiteService";

export const namespaced = true;

export const state = {
    websites: [],
    editedWebsite: { name: "", domain: "" },
    meta: null,
    links: null,
    loading: false,
    errors: null,
    responseMessage: null,
};

export const mutations = {
    SET_WEBSITES(state, payload) {
        state.websites = payload;
    },
    SET_EDITED_WEBSITE(state, payload) {
        state.editedWebsite = payload;
    },
    DELETE_WEBSITE(state, payload) {
        let index = state.websites.findIndex((e) => e.id === payload);
        if (index !== -1) {
            state.websites.splice(index, 1);
        }
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.errors = error;
    },
    SET_RESPONSE_MESSAGE(state, payload) {
        state.responseMessage = payload;
    },
};

export const actions = {
    resetForm({ commit }) {
        commit("SET_EDITED_WEBSITE", { name: null, domain: null });
    },
    getWebsites({ commit }, page) {
        commit("SET_LOADING", true);
        return WebsiteService.getWebsites(page)
            .then((response) => {
                commit("SET_WEBSITES", response.data.data);
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    getWebsite({ commit }, id) {
        commit("SET_LOADING", true);
        if (id !== "create") {
            return WebsiteService.getWebsite(id)
                .then((response) => {
                    commit("SET_EDITED_WEBSITE", response.data.data);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    commit("SET_ERROR", getErrors(error));
                    commit("SET_RESPONSE_MESSAGE", "");
                });
        }
    },
    updateWebsite({ commit }, payload) {
        commit("SET_LOADING", true);
        return WebsiteService.updateWebsite(payload)
            .then((response) => {
                commit("SET_EDITED_WEBSITE", response.data.data);
                commit("SET_RESPONSE_MESSAGE", "Website updated!");
                commit("SET_ERROR", "");
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    createWebsite({ commit }, payload) {
        commit("SET_LOADING", true);
        return WebsiteService.createWebsite(payload)
            .then((response) => {
                commit("SET_EDITED_WEBSITE", response.data.data);
                commit("SET_RESPONSE_MESSAGE", "Website created!");
                commit("SET_ERROR", "");
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    deleteWebsite({ commit }, payload) {
        return WebsiteService.deleteWebsite(payload)
            .then(() => {
                commit("DELETE_WEBSITE", payload);
                commit("SET_RESPONSE_MESSAGE", "Website deleted!");
                commit("SET_ERROR", "");
            })
            .catch((error) => {
                commit("SET_ERROR", getErrors(error));
                commit("SET_RESPONSE_MESSAGE", "");
            });
    },
    resetResponseMessage({ commit }) {
        commit("SET_RESPONSE_MESSAGE", "");
        commit("SET_ERROR", "");
    },
};

export const getters = {
    websites: (state) => {
        return state.websites;
    },
    websiteItems: (state) => {
        let res = state.websites.map((e) => {
            return { text: e.name, value: e.id };
        });
        return res;
    },
    editedWebsite: (state) => {
        return state.editedWebsite;
    },
    meta: (state) => {
        return state.meta;
    },
    links: (state) => {
        return state.links;
    },
    loading: (state) => {
        return state.loading;
    },
    responseMessage: (state) => {
        return state.responseMessage;
    },
    errors: (state) => {
        return state.errors;
    },
};
